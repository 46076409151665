export default {
  common: {
    home_subtitle: 'AIガール画像を描く',
    home_desc: 'テキストからAIの美しさへ、<br>AIで顔を入れ替え、<br>衣服を編集・削除<br>NSFWをサポート',
    purchase_desc: 'より良い価格でクレジットを購入',
    purchase_btn: '購入',
    faq_title: 'よくある質問',
    contact_us: 'お問い合わせ:',
    ending_try_desc: 'AIガールアート生成のための最高のツール、AIレディを試してみてください',
    try_btn: '今すぐ試す！',
  },

  download_btn: {
    android: 'Androidアプリ',
    ios: 'iOSアプリ',
    web: 'ウェブ版',
  },

  banner: {
    title_1: 'テキストからAIガール画像へ',
    desc_1: 'あなたの wildest 想像を現実に変える夢を見たことがありますか？私たちの新しいAI画像ジェネレーターで実現できます。理想の女の子を描写し、AIが彼女を生き生きとさせるのを見てください。',

    title_2: 'アニメジェネレーター',
    desc_2: '私たちの強力な画像ジェネレーターでアニメの世界に飛び込んでください。数回のクリックでユニークで魅力的なアニメキャラクターを作成できます。彼らの外見、スタイル、さらには感情もカスタマイズ可能です。',

    title_3: '高品質なAIアヘガオアートの作成',
    desc_3: '作成したい女の子やアニメ（漫画）キャラクターの説明を入力し、対応するアヘガオスタイルを選択して生成をクリックしてください。',

    title_4: 'AIで顔を入れ替え',
    desc_4: 'お気に入りの女の子を想像できるキャラクターに変身させるAIパワーの顔入れ替え機能を使用してください。写真をアップロードし、なりたいキャラクターを描写すると、AIがあなたの望む変身を完璧に捉えたパーソナライズされた画像を生成します。',

    title_5: '参考ポーズ',
    desc_5: '特定のポーズやスタイルに基づいたカスタム画像を作成したいですか？AIジェネレーターでは、参考画像をアップロードし、詳細な説明を入力できます。アルゴリズムがポーズの本質を捉えながら、あなたの望む要素を取り入れた画像を生成します。',

    title_6: 'AI衣服チェンジャー',
    desc_6: '写真の中の自分やお気に入りの女の子キャラクターの服を魔法のように変えたいですか？写真をアップロードすると、AIが即座に衣服を変更します。',

    title_7: 'AI消しゴム',
    desc_7: '写真をきれいにし、物体、人物、または透かしを数秒で取り除くための最良の無料ツールです。衣服の削除機能を試すには18歳以上であることを確認してください。',
  },

  faq: {
    q1: '購入時に割引価格を得るにはどうすればよいですか？',
    a1: '公式ウェブサイト aipeak.ai で割引価格で購入できます。',

    q2: '無料でクレジットを得るにはどうすればよいですか？',
    a2: '設定でリンクを共有して友達を紹介してください。友達があなたのリンクでサインアップすると、両方に10クレジットが付与されます！',

    q3: 'AIが私の要求にもっと忠実に従うようにするにはどうすればよいですか？',
    a3: 'アプリ内でAIが要求に忠実に従うようにするには、いくつかの要素を微調整することが重要です：<br><br>- プロンプト<br>- 選択したスタイル<br>- プロンプトの関連性設定<br>- 画像の品質設定<br><br>時にはプロンプトとスタイルが衝突することがありますが、スタイルの好みを強調するには「自動」を選択するか、プロンプトに括弧を付けて重みを加えることができます。',

    q4: '画像の品質が期待に応えないのはなぜですか？',
    a4: '生成された画像の品質が期待に応えない場合があるのは、いくつかの要因による可能性があります。<br><br>プロンプトや画像の品質、プロンプトの関連性パラメータを調整することで結果を改善できることがあります。選択したスタイルも結果に影響を与える可能性があります。これらの設定を試して、結果を改善することをお勧めします。',

    q5: '無料使用の回数に制限があるのはなぜですか？',
    a5: 'アプリサーバーの運営と維持にはコストがかかり、高品質なサービスを提供するために必要です。購読またはプランの購入により、これらの費用をカバーし、Proユーザーに向けて向上したサービスを提供できるようになります。<br><br>ご理解とご協力を感謝します。',

    q6: '画像生成が失敗することがあるのはなぜですか？画像生成が失敗した場合はどうなりますか？',
    a6: '画像生成の失敗は、作成された画像が確認後に特定の制限（憎悪や差別を助長するもの、露骨なコンテンツを含むなど）に違反していると判断された場合によく発生します。<br><br>画像生成が失敗した場合は、再生成を選択できます。失敗した試行で使用されたクレジットは返却され、差し引かれることはありません。',

    q7: 'どのようなコンテンツが禁止されていますか？',
    a7: '私たちは人々の好みやフェティッシュを尊重します。ただし、サイトのホスティング要件に準拠するために許可されていないコンテンツがあり、即座に削除されます。<br>- 子供のポルノ<br>- 未成年の性的描写<br>- 過度な流血<br>- 獣姦<br>- 性的暴力<br>もしそのようなコンテンツを見かけた場合は、通報してください。',
  },
}