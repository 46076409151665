import React, { useState, useRef, useEffect } from "react";
import "./home_h5.scss";
import { PayPalScriptProvider, PayPalButtons, PayPalButtonsComponentProps } from '@paypal/react-paypal-js';

const userId = new URLSearchParams(window.location.search).get(
  "id"
) as string;

const Buy: React.FC = () => {
  const products = [
    // 测试环境
    // { id: 27, title: "300 Credits", subtitle: "$19.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>1-month membership for free" },
    // { id: 28, title: "850 Credits", subtitle: "$39.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>Get lifetime membership for free" },
    // { id: 29, title: "3000 Credits", subtitle: "$79.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>Get lifetime membership for free" },
    // 线上环境
    { id: 79, title: "120 Credits", subtitle: "$9.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>1 month membership for free" },
    { id: 72, title: "300 Credits", subtitle: "$19.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>1 month membership for free" },
    { id: 73, title: "700 Credits", subtitle: "$39.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>Get lifetime membership for free" },
    { id: 74, title: "2000 Credits", subtitle: "$79.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>Get lifetime membership for free" },
    { id: 82, title: "20 Credits (First payment only)", subtitle: "$1.99", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>Get lifetime membership for free" },
  ];

  const [selectedProduct, setSelectedProduct] = useState<number>(products.length > 0 ? products[0].id : -1);
  const selectedProductRef = useRef<number>(-1);

  const selectProduct = (productId: number) => {
    setSelectedProduct(productId);
  };

  useEffect(() => {
    selectedProductRef.current = selectedProduct;
  }, [selectedProduct]);

  const getBenefits = (productId: number) => {
    const product = products.find(p => p.id === productId);
    return {
      benefitsTitle: product ? product.benefitsTitle : '',
      benefitsBody: product ? product.benefitsBody : ''
    };
  };

  const { benefitsTitle, benefitsBody } = getBenefits(selectedProduct);

  const createOrder: PayPalButtonsComponentProps["createOrder"] = async () => {
    try {
      const response = await fetch("/api/account/paypal/order/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          mdseId: selectedProductRef.current,
          userId: userId,
          // _sandbox: true, //测试环境
          _sandbox: false, //线上环境
        }),
      });

      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      } else {
        throw new Error(orderData.errorMessage);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onApprove: PayPalButtonsComponentProps["onApprove"] = async (data) => {
    // Capture the funds from the transaction.
    const response = await fetch("/api/account/paypal/order/capture", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        orderId: data.orderID,
        // _sandbox: true, //测试环境
        _sandbox: false, //线上环境
      }),
    });

    const orderData = await response.json();

    if (orderData.errorMessage) {
      throw new Error(orderData.errorMessage);
    } else {
      // Show success message to buyer
      alert(`Purchase successful! Your credits are ready to use.`);
    }
  };

  const onError: PayPalButtonsComponentProps["onError"] = async (error) => {
    alert(error.message);
  }

  // const handlePayPalButtonClick = () => {
  //   if (selectedProductRef.current === -1 || userIdRef.current === '') {
  //     throw new Error('Please select a product and enter your user ID.');
  //   }
  // };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center buy-bg">
        <div className="paypal-popup p-8 rounded-lg relative">
          {/* <div className="flex justify-between items-center">
            <h2 className="paypal-popup-title">Buy Credits</h2>
            <button className="close-button absolute top-4 right-4" onClick={toggleModal}>×</button>
          </div> */}
          <div className="flex justify-start items-start">
            <p className="benefits-title" dangerouslySetInnerHTML={{ __html: benefitsTitle }} />
          </div>
          <div className="flex justify-start items-start mb-4">
            <p className="benefits-body" dangerouslySetInnerHTML={{ __html: benefitsBody }} />
          </div>
          {products.map((product, index) => (
            <div
              key={product.id}
              className={`product-button p-4 mb-4 cursor-pointer ${selectedProduct === product.id ? 'selected' : ''}`}
              onClick={() => selectProduct(product.id)}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <h3 className="text-lg font-bold">{product.title}</h3>
              <h3 className="text-lg text-right">{product.subtitle}</h3>
            </div>
          ))}
          <div className="text-center">
            <p className="select-payment-method mx-auto">Select payment method</p>
          </div>
          <div className="mt-4">
            {/* 测试环境 */}
            {/* <PayPalScriptProvider options={{ 'clientId': 'AZjfKeey1JWTmPiOaGZmQuZqNCZxiFCYMI8kZOljm8a5zm7F7LnNL3hUnjN0VMqIPbhI4fCICl53G_y3' }}> */}
            {/* 线上环境 */}
            <PayPalScriptProvider options={{ 'clientId': 'Abm86RUTYo506jNuEObrcj826jWyy_YuzyqfL_S6x2tQY_mHyW8DFnFukKdiYTaX8rm-xNhS_mt0fIOj' }}>
              <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
              // onClick={handlePayPalButtonClick}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Buy;
