import Web from "../components/home/Home_web";
import H5 from "../components/home/Home_h5";
import BUY from "../components/home/Buy";
import SUBSCRIBE from "../components/home/Subscribe";
import { isMobile } from "../service/util";

function Home() {
  return isMobile() ? <H5 /> : <Web />;
}

function Buy() {
  return <BUY />;
}

function Subscribe() {
  return <SUBSCRIBE />;
}

export default Home;
