export default {
  common: {
    home_subtitle: 'Zeichnen Sie Ihre KI-Mädchenbilder',
    home_desc: 'Von Text zu KI-Schönheit<br>Gesicht mit KI austauschen<br>Bearbeiten und Entfernen von Kleidung<br>Unterstützt NSFW',
    purchase_desc: 'Credits zu einem besseren Preis kaufen',
    purchase_btn: 'Kaufen',
    faq_title: 'Häufig gestellte Fragen',
    contact_us: 'Kontaktieren Sie uns:',
    ending_try_desc: 'Probieren Sie AI Lady aus, das beste Tool zur Erstellung von KI-Mädchenkunst',
    try_btn: 'Jetzt ausprobieren!',
  },

  download_btn: {
    android: 'Android-App',
    ios: 'iOS-App',
    web: 'Web-Version',
  },

  banner: {
    title_1: 'Von Text zum KI-Mädchenbild',
    desc_1: 'Haben Sie schon immer davon geträumt, Ihre wildesten Vorstellungen in die Realität umzusetzen? Jetzt können Sie das mit unserem neuen KI-Bildgenerator. Beschreiben Sie einfach Ihr ideales Mädchen und sehen Sie zu, wie unsere KI sie zum Leben erweckt.',

    title_2: 'Anime-Generator',
    desc_2: 'Tauchen Sie mit unserem leistungsstarken Bildgenerator in die Welt des Anime ein. Erstellen Sie einzigartige und fesselnde Anime-Charaktere mit nur wenigen Klicks. Passen Sie ihr Aussehen, ihren Stil und sogar ihre Emotionen an.',

    title_3: 'Hochwertige KI-Ahegao-Kunst erstellen',
    desc_3: 'Geben Sie einfach die Beschreibung des Mädchens oder des Anime (Manga)-Charakters ein, den Sie möchten, wählen Sie den entsprechenden Ahegao-Stil aus und klicken Sie auf Generieren.',

    title_4: 'Gesicht mit KI austauschen',
    desc_4: 'Verwandeln Sie Ihr Lieblingsmädchen in jeden Charakter, den Sie sich vorstellen können, mit unserer KI-unterstützten Gesichtstausch-Funktion. Laden Sie einfach Ihr Foto hoch und beschreiben Sie den Charakter, den Sie verkörpern möchten, und unsere KI wird ein personalisiertes Bild erstellen, das Ihre gewünschte Verwandlung perfekt einfängt.',

    title_5: 'Referenzpose',
    desc_5: 'Möchten Sie benutzerdefinierte Bilder basierend auf bestimmten Posen oder Stilen erstellen? Unser KI-Generator ermöglicht es Ihnen, ein Referenzbild hochzuladen und eine detaillierte Beschreibung einzugeben. Unser Algorithmus erstellt dann Bilder, die das Wesen der Pose einfangen und Ihre gewünschten Elemente einbeziehen.',

    title_6: 'KI-Kleidungswechsler',
    desc_6: 'Suchen Sie nach einer Möglichkeit, die Kleidung Ihres oder Ihres Lieblingsmädchencharakters in einem Foto auf magische Weise zu ändern? Laden Sie ein Bild hoch und lassen Sie unsere KI die Kleidung nach Bedarf sofort ändern.',

    title_7: 'KI-Radierer',
    desc_7: 'Das beste kostenlose Tool, um Ihre Bilder zu bereinigen und innerhalb von Sekunden jedes Objekt, jede Person oder Wasserzeichen zu entfernen. Stellen Sie sicher, dass Sie über 18 Jahre alt sind, wenn Sie die Funktion zum Radieren von Kleidung ausprobieren möchten.',
  },

  faq: {
    q1: 'Wie erhalte ich einen Rabatt auf meinen Einkauf?',
    a1: 'Sie können zu einem ermäßigten Preis auf unserer offiziellen Website aipeak.ai einkaufen',

    q2: 'Wie kann ich kostenlos Credits erhalten?',
    a2: 'Empfehlen Sie Freunde, indem Sie Ihren Link in den Einstellungen teilen. Wenn sich ein Freund über Ihren Link anmeldet, erhalten Sie beide 10 Credits!',

    q3: 'Wie kann ich sicherstellen, dass die KI meinen Anforderungen besser entspricht?',
    a3: 'Um sicherzustellen, dass die KI Ihre Anforderungen in der App genau erfüllt, ist es entscheidend, mehrere Aspekte zu optimieren: <br><br>- den Prompt, <br>- den gewählten Stil, <br>- die Relevanzeinstellungen des Prompts, <br>- und die Bildqualitätseinstellungen. <br><br>Manchmal können Prompts und Stile widersprüchlich sein, aber Sie können Ihre Stilvorlieben betonen, indem Sie \'auto\' auswählen oder Klammern in Ihren Prompt einfügen, um zusätzliches Gewicht zu verleihen.',

    q4: 'Warum entspricht die Bildqualität nicht meinen Erwartungen?',
    a4: 'Die Qualität des generierten Bildes entspricht möglicherweise nicht immer Ihren Erwartungen aufgrund mehrerer Faktoren. <br><br>Die Anpassung des Prompts, der Bildqualität oder der Relevanzparameter des Prompts könnte das Ergebnis verbessern. Der gewählte Stil könnte ebenfalls Einfluss auf das Ergebnis haben. Wir empfehlen, mit diesen Einstellungen zu experimentieren, um bessere Ergebnisse zu erzielen.',

    q5: 'Warum gibt es eine Begrenzung der Anzahl kostenloser Nutzungen?',
    a5: 'Der Betrieb und die Wartung unserer App-Server sind kostspielig und notwendig, um qualitativ hochwertige Dienstleistungen anzubieten. Ein Abonnement oder der Kauf von Plänen unterstützt uns bei der Deckung dieser Kosten und ermöglicht es uns, verbesserte Dienstleistungen für Pro-Nutzer anzubieten. <br><br>Wir schätzen Ihr Verständnis und Ihre Unterstützung.',

    q6: 'Warum scheitern Bildgenerierungen manchmal? Was passiert, wenn eine Bildgenerierung fehlschlägt?',
    a6: 'Fehler bei der Bildgenerierung treten häufig bei Nicht-Mitgliedern auf, wenn die erstellten Bilder bei der Überprüfung bestimmte Einschränkungen verletzen, wie z.B. die Förderung von Hass, Diskriminierung oder expliziten Inhalten. <br><br>Wenn die Bildgenerierung fehlschlägt, können Sie die Bildgenerierung erneut versuchen. Seien Sie versichert, dass die für einen fehlgeschlagenen Versuch verwendeten Credits Ihnen zurückerstattet und nicht abgezogen werden.',

    q7: 'Welche Art von Inhalten ist verboten?',
    a7: 'Wir respektieren die Vorlieben und Fetische der Menschen. Es gibt jedoch bestimmte Arten von Inhalten, die auf dieser Seite nicht akzeptabel sind und sofort entfernt werden, um sicherzustellen, dass die Seite den Hosting-Anforderungen entspricht.<br>- Kinderpornografie<br>- Sexualisierte Darstellungen von Minderjährigen<br>- Starke Gewalt<br>- Zoophilie<br>- Sexuelle Gewalt<br>Bitte helfen Sie uns, Inhalte zu melden, wenn Sie diese sehen.',
  },
}