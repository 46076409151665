export default {
  common: {
    home_subtitle: 'Images de Filles AI NSFW',
    home_desc: 'De texte à beauté IA<br>Échangez de visage avec IA<br>Éditez et effacez tout vêtement<br>Supporte NSFW',
    purchase_desc: 'Achetez des crédits à un meilleur prix',
    purchase_btn: 'Acheter',
    faq_title: 'Questions Fréquemment Posées',
    contact_us: 'Contactez-nous :',
    ending_try_desc: 'Essayez AI Lady, le meilleur outil pour générer de l’art de filles IA',
    try_btn: 'Essayez maintenant !',
  },

  download_btn: {
    android: 'Android App',
    ios: 'iOS App',
    web: 'Version Web',
  },

  banner: {
    title_1: 'Du Texte à l’Image de Fille AI',
    desc_1: 'Avez-vous déjà rêvé de transformer votre imagination la plus folle en réalité ? Maintenant, c’est possible avec notre nouveau générateur d’images AI NSFW. Décrivez simplement votre fille idéale et regardez notre AI lui donner vie.',

    title_2: 'Générateur Anime NSFW',
    desc_2: 'Entrez dans le monde de l’anime avec notre puissant générateur d’images. Créez des personnages anime uniques et captivants en quelques clics seulement. Personnalisez leur apparence, leur style et même leurs émotions.',

    title_3: 'Créez des Arts Ahegao AI NSFW',
    desc_3: 'Il suffit de saisir la description de la fille ou du personnage anime (manga) que vous souhaitez, de sélectionner le style Ahegao correspondant et de cliquer pour le générer.',

    title_4: 'Échange de Visage avec AI',
    desc_4: 'Transformez votre fille préférée en tout personnage que vous pouvez imaginer avec notre fonction d’échange de visage alimentée par AI. Téléchargez simplement votre photo et décrivez le personnage que vous souhaitez incarner, et notre AI générera une image personnalisée qui capture parfaitement votre transformation désirée.',

    title_5: 'Pose de Référence',
    desc_5: 'Vous souhaitez créer des images personnalisées basées sur des poses ou des styles spécifiques ? Notre générateur AI vous permet de télécharger une image de référence et de saisir une description détaillée. Notre algorithme générera ensuite des images qui capturent l’essence de la pose tout en intégrant les éléments souhaités.',

    title_6: 'Changeur de Vêtements AI',
    desc_6: 'Vous cherchez un moyen de changer magiquement les vêtements de votre personnage ou de votre fille préférée sur une photo ? Téléchargez une image et laissez notre AI changer les vêtements pour vous immédiatement selon vos besoins.',

    title_7: 'Gomme AI / Retirer Tout Vêtement',
    desc_7: 'Le meilleur outil gratuit pour nettoyer vos photos et supprimer tout objet, personne ou filigrane en quelques secondes. Assurez-vous d’avoir plus de 18 ans si vous souhaitez essayer la fonction de suppression de vêtements.',
  },

  faq: {
    q1: 'Comment obtenir un prix réduit pour mon achat ?',
    a1: 'Vous pouvez acheter à prix réduit sur notre site officiel à nsfw100.com',

    q2: 'Comment puis-je obtenir des crédits gratuitement ?',
    a2: 'Référez des amis en partageant votre lien dans les paramètres. Lorsqu’un ami s’inscrit avec votre lien, vous obtiendrez tous les deux 10 crédits !',

    q3: 'Comment faire en sorte que l’IA respecte mieux mes exigences ?',
    a3: 'Pour garantir que l’IA suive de près vos exigences dans l’application, il est crucial de peaufiner plusieurs aspects : <br><br>- le prompt, <br>- le style choisi, <br>- les paramètres de pertinence du prompt, <br>- et les paramètres de qualité de l’image. <br><br>Parfois, les prompts et les styles peuvent entrer en conflit, mais vous pouvez souligner votre préférence de style en sélectionnant « auto » ou en appliquant des parenthèses à votre prompt pour plus de poids.',

    q4: 'Pourquoi la qualité de l’image ne répond-elle pas à mes attentes ?',
    a4: 'La qualité de l’image générée peut ne pas toujours correspondre à vos attentes en raison de plusieurs facteurs. <br><br>Adjuster le prompt, la qualité de l’image ou les paramètres de pertinence du prompt pourrait améliorer le résultat. Le style choisi peut également influencer le résultat. Nous recommandons d’expérimenter avec ces paramètres pour de meilleurs résultats.',

    q5: 'Pourquoi y a-t-il une limite au nombre d’utilisations gratuites ?',
    a5: 'L’exploitation et la maintenance de nos serveurs d’application sont coûteuses, nécessaires pour offrir des services de haute qualité. S’abonner ou acheter des forfaits nous aide à couvrir ces dépenses et nous permet d’offrir des services améliorés aux utilisateurs Pro. <br><br>Nous apprécions votre compréhension et votre soutien.',

    q6: 'Pourquoi les générations d’images échouent-elles parfois ? Que se passe-t-il si une génération d’image échoue ?',
    a6: 'Les échecs de génération d’images se produisent souvent pour les non-membres lorsque les images créées, après examen, sont jugées contrevenir à certaines restrictions, telles que la promotion de la haine, la discrimination ou le contenu explicite. <br><br>En cas d’échec de génération d’image, vous pouvez choisir de régénérer l’image. Soyez assuré que les crédits utilisés pour une tentative échouée vous sont retournés et ne sont pas déduits.',

    q7: 'Quel type de contenu est interdit ?',
    a7: 'Nous respectons les goûts et les fantasmes des gens. Cependant, certains types de contenu ne sont pas acceptables sur ce site et seront immédiatement supprimés pour garantir que le site respecte les exigences d’hébergement. <br>- Pornographie infantile<br>- Représentations sexualisées de mineurs<br>- Gore extrême<br>- Bestialité<br>- Violence sexuelle<br>Veuillez nous aider à signaler le contenu si vous le voyez.',
  },
}