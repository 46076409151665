export default {
  common: {
    home_subtitle: 'NSFW AI 女孩圖片',
    home_desc: '從文字到 AI 美女<br>使用 AI 更換臉孔<br>編輯和刪除任何衣物<br>支持 NSFW',
    purchase_desc: '以更優惠的價格購買點數',
    purchase_btn: '購買',
    faq_title: '常見問題',
    contact_us: '聯繫我們：',
    ending_try_desc: '試用 AI Lady，最佳的 AI 女孩藝術生成工具',
    try_btn: '立即試用！',
  },

  download_btn: {
    android: '安卓 App',
    ios: 'iOS App',
    web: '網頁版',
  },

  banner: {
    title_1: '從文字到 AI 女孩圖片',
    desc_1: '曾經夢想過將你最狂野的想像變成現實嗎？現在你可以使用我們全新的 NSFW AI 圖片生成器來實現。只需描述你理想中的女孩，然後觀看我們的 AI 如何讓她栩栩如生。',

    title_2: 'NSFW 動漫生成器',
    desc_2: '通過我們強大的圖片生成器步入動漫世界。只需幾次點擊，就能創造出獨特且迷人的動漫角色。自定義他們的外貌、風格，甚至是情感。',

    title_3: '創建 NSFW AI Ahegao 藝術',
    desc_3: '只需輸入你想要的女孩或動漫（漫畫）角色的描述，選擇對應的 Ahegao 風格，然後點擊生成。',

    title_4: '使用 AI 換臉',
    desc_4: '通過我們的 AI 驅動的換臉功能，將你最喜愛的女孩變成任何你能想像的角色。只需上傳你的照片並描述你想要呈現的角色，我們的 AI 就會生成一個完美捕捉你期望轉變的個性化圖片。',

    title_5: '參考姿勢',
    desc_5: '想要根據特定的姿勢或風格創建自訂圖片？我們的 AI 生成器允許你上傳參考圖片並輸入詳細描述。我們的算法將生成捕捉姿勢精髓的圖片，同時融合你所希望的元素。',

    title_6: 'AI 服裝變換器',
    desc_6: '尋找一種神奇的方法來改變照片中你或你最喜愛的女孩角色的衣服？上傳一張照片，然後讓我們的 AI 立即根據需要更換衣服。',

    title_7: 'AI 擦除器 / 移除任何衣物',
    desc_7: '最佳免費工具，用於清理你的圖片並在幾秒鐘內移除任何物體、人物或水印。如果你想嘗試擦除衣物功能，請確保你年滿 18 歲。',
  },

  faq: {
    q1: '我如何獲得購買的折扣價格？',
    a1: '你可以在我們的官方網站 nsfw100.com 上以折扣價格購買。',
    
    q2: '我如何免費獲得點數？',
    a2: '通過在設置中分享你的鏈接來邀請朋友。當朋友使用你的鏈接註冊時，你們兩人都會獲得 10 個點數！',

    q3: '我如何讓 AI 更加符合我的要求？',
    a3: '要確保 AI 在應用中更精確地遵循你的要求，關鍵是調整以下幾個方面： <br><br>- 提示詞， <br>- 選擇的風格， <br>- 提示詞相關設置， <br>- 圖像質量設置。 <br><br>有時提示詞和風格可能會沖突，但你可以通過選擇“自動”或在提示詞中使用括號來強調你的風格偏好。',
    
    q4: '為什麼圖像質量不符合我的期望？',
    a4: '生成的圖像質量可能由於多種因素而無法始終符合你的期望。 <br><br>調整提示詞、圖像質量或提示詞相關參數可能會改善結果。選擇的風格也可能影響結果。我們建議你嘗試這些設置以獲得更好的結果。',
    
    q5: '為什麼免費使用次數有限制？',
    a5: '我們應用服務器的運行和維護成本高昂，這是提供高質量服務所必需的。訂閱或購買計劃支持我們覆蓋這些開支，並使我們能夠為專業用戶提供更好的服務。 <br><br>感謝你的理解和支持。',
    
    q6: '為什麼圖像生成有時會失敗？如果圖像生成失敗怎麼辦？',
    a6: '圖像生成失敗通常發生在非會員時，當生成的圖像在審核中被發現違反某些限制，如宣揚仇恨、歧視或包含明顯內容。 <br><br>如果圖像生成失敗，你可以選擇重新生成圖像。請放心，失敗嘗試所使用的點數會返回給你，不會被扣除。',
    
    q7: '哪些內容是禁止的？',
    a7: '我們尊重人們的品味和癖好。然而，有些類型的內容在本站是不允許的，會立即被刪除，以確保本站符合托管要求。<br>- 兒童色情<br>- 性別化的未成年描繪<br>- 嚴重血腥暴力<br>- 獸交<br>- 性暴力<br>如果你看到這些內容，請幫助我們報告。',
  },
}