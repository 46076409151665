export default {
  common: {
    home_subtitle: '绘制你的AI女孩图像',
    home_desc: '从文字到 AI 美女<br>使用 AI 更换面部<br>编辑和擦除任何服装<br>支持 NSFW',
    purchase_desc: '以更优惠的价格购买点数',
    purchase_btn: '购买',
    faq_title: '常见问题',
    contact_us: '联系我们',
    ending_try_desc: '试用 AI Lady，最佳的 AI 女孩艺术生成工具',
    try_btn: '立即试用！',
  },

  download_btn: {
    android: '安卓 App',
    ios: 'iOS App',
    web: '网页版',
  },

  banner: {
    title_1: '从文本到 AI 女孩图像',
    desc_1: '曾经梦想将你最狂野的想象变为现实吗？现在你可以通过我们的新 AI 图像生成器实现。只需描述你理想中的女孩，看看我们的 AI 如何将她带入生活。',

    title_2: '动漫生成器',
    desc_2: '使用我们强大的图像生成器，步入动漫世界。仅需几次点击即可创建独特迷人的动漫角色。自定义他们的外貌、风格，甚至是他们的情感。',

    title_3: '创建高质量 AI Ahegao 艺术',
    desc_3: '只需输入你想要的女孩或动漫（漫画）角色的描述，选择对应的 Ahegao 风格，然后点击生成。',

    title_4: '使用 AI 更换面孔',
    desc_4: '利用我们的 AI 面孔更换功能，将你最喜欢的女孩变成你能想象的任何角色。只需上传你的照片，描述你想要体现的角色，我们的 AI 将生成一个完美捕捉你想要变换效果的个性化图像。',

    title_5: '参考姿势',
    desc_5: '想要根据特定的姿势或风格创建自定义图像？我们的 AI 生成器允许你上传参考图像并输入详细描述。我们的算法将生成捕捉姿势精髓的图像，同时融入你想要的元素。',

    title_6: 'AI 服装更换器',
    desc_6: '寻找一种神奇的方式来更改你或你最喜欢的女孩角色的照片中的衣服？上传一张照片，让我们的 AI 根据需要立即更换衣服。',

    title_7: 'AI 橡皮擦',
    desc_7: '最好的免费工具，能在几秒钟内清理你的照片，移除任何物体、人物或水印。如果你想尝试擦除衣物功能，请确保你已年满 18 岁。',
  },

  faq: {
    q1: '如何获得购买的折扣价格？',
    a1: '您可以在我们的网站 aipeak.ai 上以折扣价购买。',

    q2: '如何免费获得点数？',
    a2: '通过在设置中分享您的链接来推荐朋友。当朋友通过您的链接注册时，您和您的朋友都将获得 10 个点数！',

    q3: '如何让 AI 更加符合我的要求？',
    a3: '为了确保 AI 在应用中更好地遵循您的要求，您需要微调以下几个方面：<br><br>- 提示，<br>- 选择的风格，<br>- 提示相关性设置，<br>- 和图像质量设置。<br><br>有时，提示和风格可能会冲突，但您可以通过选择“自动”或对提示应用括号来强调您的风格偏好，以增加权重。',

    q4: '为什么图像质量不符合我的期望？',
    a4: '生成的图像质量可能不会总是符合您的期望，这可能是由于多个因素造成的。<br><br>调整提示、图像质量或提示相关性参数可能会改善结果。选择的风格也会影响结果。我们建议您尝试调整这些设置以获得更好的效果。',

    q5: '为什么免费使用次数有限制？',
    a5: '我们应用服务器的运行和维护成本很高，这对于提供高质量服务是必要的。订阅或购买计划可以帮助我们覆盖这些费用，并使我们能够为专业用户提供更好的服务。<br><br>感谢您的理解和支持。',

    q6: '为什么图像生成有时会失败？如果图像生成失败了怎么办？',
    a6: '图像生成失败通常发生在非会员中，当生成的图像经过审核后发现违反了某些限制，比如宣传仇恨、歧视或包含露骨内容。<br><br>如果图像生成失败，您可以选择重新生成图像。请放心，失败尝试所使用的点数将退还给您，而不是被扣除。',

    q7: '哪些内容是禁止的？',
    a7: '我们尊重人们的品味和癖好。然而，某些类型的内容在本站是不被接受的，并会立即被删除，以确保网站符合托管要求。<br>- 儿童色情<br>- 性暗示的未成年人描绘<br>- 重度血腥<br>- 性虐待动物<br>- 性暴力<br>如果您看到这些内容，请帮助我们举报。',
  },
}