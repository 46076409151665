export default {
  common: {
    home_subtitle: 'AI 소녀 이미지 그리기',
    home_desc: '텍스트에서 AI 아름다움으로<br>AI와 얼굴 바꾸기<br>모든 의상 편집 및 지우기<br>NSFW 지원',
    purchase_desc: '더 나은 가격으로 크레딧 구매하기',
    purchase_btn: '구매하기',
    faq_title: '자주 묻는 질문',
    contact_us: '문의하기:',
    ending_try_desc: 'AI 소녀 아트 생성에 최적화된 도구, AI Lady를 사용해보세요',
    try_btn: '지금 시도하기!',
  },

  download_btn: {
    android: '안드로이드 앱',
    ios: 'iOS 앱',
    web: '웹 버전',
  },

  banner: {
    title_1: '텍스트에서 AI 소녀 이미지로',
    desc_1: '가장 환상적인 상상을 현실로 바꾸고 싶었던 적이 있나요? 이제 우리 새로운 AI 이미지 생성기로 가능합니다. 이상적인 소녀를 설명하기만 하면, AI가 그녀를 생생하게 만들어 드립니다.',

    title_2: '애니메이션 생성기',
    desc_2: '강력한 이미지 생성기로 애니메이션의 세계에 발을 들여보세요. 몇 번의 클릭으로 독특하고 매력적인 애니메이션 캐릭터를 생성하세요. 그들의 외모, 스타일, 감정까지 커스터마이즈할 수 있습니다.',

    title_3: '고품질 AI 아헤가오 아트 생성',
    desc_3: '원하는 소녀나 애니메이션(만화) 캐릭터의 설명을 입력하고, 해당 아헤가오 스타일을 선택한 후 생성 버튼을 클릭하세요.',

    title_4: 'AI와 얼굴 바꾸기',
    desc_4: 'AI 기반 얼굴 바꾸기 기능으로 좋아하는 소녀를 상상할 수 있는 어떤 캐릭터로든 변신시키세요. 사진을 업로드하고 원하는 캐릭터를 설명하기만 하면, AI가 당신의 원하는 변신을 완벽하게 담은 맞춤형 이미지를 생성합니다.',

    title_5: '참조 포즈',
    desc_5: '특정 포즈나 스타일을 바탕으로 커스터마이즈된 이미지를 만들고 싶나요? 참조 이미지를 업로드하고 자세한 설명을 입력하면, 알고리즘이 포즈의 본질을 포착하면서 원하는 요소를 포함한 이미지를 생성합니다.',

    title_6: 'AI 옷 변경기',
    desc_6: '사진에서 자신이나 좋아하는 캐릭터의 옷을 마법처럼 바꿀 방법을 찾고 있나요? 사진을 업로드하면 AI가 필요한 옷으로 즉시 변경해 드립니다.',

    title_7: 'AI 지우개',
    desc_7: '사진에서 어떤 물체, 사람, 워터마크든 몇 초 만에 지울 수 있는 최고의 무료 도구입니다. 의상 지우기 기능을 시도하려면 18세 이상이어야 합니다.',
  },

  faq: {
    q1: '구매 시 할인된 가격을 어떻게 받을 수 있나요?',
    a1: '공식 웹사이트 aipeak.ai에서 할인된 가격으로 구매할 수 있습니다.',

    q2: '무료로 크레딧을 어떻게 얻을 수 있나요?',
    a2: '설정에서 링크를 공유하여 친구를 추천하세요. 친구가 당신의 링크로 가입하면, 두 분 모두 10 크레딧을 받게 됩니다!',

    q3: 'AI가 내 요구 사항을 더 잘 따르게 하려면 어떻게 해야 하나요?',
    a3: '앱에서 AI가 요구 사항을 잘 따르도록 하려면 여러 측면을 조정하는 것이 중요합니다: <br><br>- 프롬프트, <br>- 선택한 스타일, <br>- 프롬프트 관련성 설정, <br>- 이미지 품질 설정. <br><br>가끔 프롬프트와 스타일이 충돌할 수 있지만, \'자동\'을 선택하거나 프롬프트에 괄호를 적용하여 스타일 선호도를 강조할 수 있습니다.',

    q4: '왜 이미지 품질이 내 기대에 미치지 않나요?',
    a4: '생성된 이미지의 품질이 항상 기대에 부합하지 않을 수 있는 여러 요인이 있습니다. <br><br>프롬프트, 이미지 품질, 또는 프롬프트 관련성 매개변수를 조정하면 결과가 개선될 수 있습니다. 선택한 스타일도 결과에 영향을 미칠 수 있습니다. 이러한 설정을 실험해보시기 바랍니다.',

    q5: '왜 무료 사용 횟수에 제한이 있나요?',
    a5: '앱 서버의 운영 및 유지 관리에는 비용이 들며, 이는 고품질 서비스를 제공하는 데 필요합니다. 구독하거나 계획을 구매하면 이러한 비용을 지원하고 Pro 사용자에게 향상된 서비스를 제공할 수 있습니다. <br><br>이해와 지원에 감사드립니다.',

    q6: '왜 이미지 생성이 가끔 실패하나요? 이미지 생성이 실패하면 어떻게 되나요?',
    a6: '이미지 생성 실패는 비회원의 경우 종종 발생하며, 생성된 이미지가 검토 시 특정 제한 사항을 위반하는 것으로 판명될 수 있습니다. <br><br>이미지 생성이 실패할 경우 이미지를 다시 생성할 수 있습니다. 실패 시 사용된 크레딧은 차감되지 않고 반환됩니다.',

    q7: '어떤 종류의 콘텐츠가 금지되나요?',
    a7: '우리는 사람들의 취향과 성적 취향을 존중합니다. 그러나 이 사이트에서 허용되지 않는 특정 유형의 콘텐츠가 있으며, 사이트가 호스팅 요구 사항을 준수하도록 하기 위해 즉시 삭제됩니다.<br>- 아동 포르노그래피<br>- 미성년자의 성적 묘사<br>- 심한 폭력<br>- 짐승과의 성행위<br>- 성적 폭력<br>해당 콘텐츠를 발견하면 신고해 주시기 바랍니다.',
  },
}