export default {
  common: {
    home_subtitle: 'Immagini di Ragazze AI NSFW',
    home_desc: 'Dal Testo alla Bellezza AI<br>Scambia il Volto con l\'AI<br>Modifica e Cancella Qualsiasi Abbigliamento<br>Supporta NSFW',
    purchase_desc: 'Acquista Crediti a un Prezzo Migliore',
    purchase_btn: 'Acquista',
    faq_title: 'Domande Frequenti',
    contact_us: 'Contattaci:',
    ending_try_desc: 'Prova AI Lady, il miglior strumento per generare arte di ragazze AI',
    try_btn: 'Provalo Ora!',
  },

  download_btn: {
    android: 'App Android',
    ios: 'App iOS',
    web: 'Versione Web',
  },

  banner: {
    title_1: 'Da Testo a Immagine di Ragazza AI',
    desc_1: 'Hai mai sognato di trasformare la tua immaginazione più sfrenata in realtà? Ora puoi farlo con il nostro nuovo generatore di immagini AI NSFW. Descrivi semplicemente la tua ragazza ideale e guarda come la nostra AI la porta in vita.',

    title_2: 'Generatore Anime NSFW',
    desc_2: 'Entra nel mondo dell’anime con il nostro potente generatore di immagini. Crea personaggi anime unici e affascinanti con solo pochi clic. Personalizza il loro aspetto, stile e persino le loro emozioni.',

    title_3: 'Crea Arte Ahegao AI NSFW',
    desc_3: 'Inserisci semplicemente la descrizione della ragazza o del personaggio anime (manga) che desideri, seleziona lo stile Ahegao corrispondente e clicca per generarlo.',

    title_4: 'Scambia Volto con AI',
    desc_4: 'Trasforma la tua ragazza preferita in qualsiasi personaggio tu possa immaginare con la nostra funzione di scambio di volto alimentata da AI. Carica semplicemente la tua foto e descrivi il personaggio che desideri incarnare, e la nostra AI genererà un’immagine personalizzata che cattura perfettamente la trasformazione desiderata.',

    title_5: 'Posa di Riferimento',
    desc_5: 'Vuoi creare immagini personalizzate basate su pose o stili specifici? Il nostro generatore AI ti permette di caricare un’immagine di riferimento e inserire una descrizione dettagliata. Il nostro algoritmo genererà quindi immagini che catturano l’essenza della posa incorporando gli elementi desiderati.',

    title_6: 'Cambiamento di Vestiti AI',
    desc_6: 'Cerchi un modo per cambiare magicamente i vestiti del tuo personaggio o della tua ragazza preferita in una foto? Carica un’immagine e lascia che la nostra AI cambi i vestiti per te immediatamente secondo le tue necessità.',

    title_7: 'Gomma AI / Rimuovi Qualsiasi Indumento',
    desc_7: 'Il miglior strumento gratuito per pulire le tue foto e rimuovere qualsiasi oggetto, persona o filigrana in pochi secondi. Assicurati di avere più di 18 anni se desideri provare la funzione di rimozione degli indumenti.',
  },

  faq: {
    q1: 'Come posso ottenere un prezzo scontato per il mio acquisto?',
    a1: 'Puoi acquistare a un prezzo scontato sul nostro sito ufficiale a nsfw100.com',

    q2: 'Come posso ottenere crediti gratuitamente?',
    a2: 'Invita amici condividendo il tuo link nelle impostazioni. Quando un amico si iscrive con il tuo link, entrambi riceverete 10 crediti!',

    q3: 'Come posso fare in modo che l\'AI aderisca meglio alle mie richieste?',
    a3: 'Per garantire che l\'AI segua da vicino le tue richieste nell\'app, è fondamentale perfezionare diversi aspetti: <br><br>- il prompt, <br>- lo stile scelto, <br>- le impostazioni di rilevanza del prompt, <br>- e le impostazioni di qualità dell\'immagine. <br><br>A volte, i prompt e gli stili potrebbero contrastare, ma puoi enfatizzare la tua preferenza stilistica selezionando \'auto\' o applicando parentesi al tuo prompt per dare maggiore peso.',

    q4: 'Perché la qualità dell\'immagine non soddisfa le mie aspettative?',
    a4: 'La qualità dell\'immagine generata potrebbe non sempre allinearsi alle tue aspettative a causa di diversi fattori. <br><br>Regolare il prompt, la qualità dell\'immagine o i parametri di rilevanza del prompt potrebbe migliorare il risultato. Lo stile scelto potrebbe anche influenzare il risultato. Ti consigliamo di sperimentare con queste impostazioni per risultati migliori.',

    q5: 'Perché c\'è un limite al numero di utilizzi gratuiti?',
    a5: 'Il funzionamento e la manutenzione dei nostri server sono costosi, necessari per offrire servizi di alta qualità. Abbonarsi o acquistare piani ci aiuta a coprire queste spese e ci consente di offrire servizi migliorati agli utenti Pro. <br><br>Apprezziamo la tua comprensione e supporto.',

    q6: 'Perché a volte le generazioni di immagini falliscono? Cosa succede se una generazione di immagini fallisce?',
    a6: 'I fallimenti nella generazione delle immagini si verificano spesso per i non membri quando le immagini create, dopo la revisione, risultano violare determinate restrizioni, come la promozione dell\'odio, discriminazione o contenuti espliciti. <br><br>Se la generazione dell\'immagine fallisce, puoi scegliere di rigenerare l\'immagine. Stai tranquillo, i crediti utilizzati per un tentativo fallito ti verranno restituiti e non saranno dedotti.',

    q7: 'Che tipo di contenuti sono vietati?',
    a7: 'Rispettiamo i gusti e le inclinazioni delle persone. Tuttavia, ci sono determinati tipi di contenuto che non sono accettabili su questo sito e verranno rimossi immediatamente per garantire che il sito rispetti i requisiti di hosting.<br>- Pornografia infantile<br>- Rappresentazioni sessualizzate di minori<br>- Gore estremo<br>- Bestialità<br>- Violenza sessuale<br>Per favore, aiutaci a segnalare il contenuto se lo vedi.',
  },
}